import React, { useEffect, useRef, useState } from 'react';
import { Header } from './layout/header';
import videoBackground from './videos/moshed.webm';
import videoBackgroundCyan from './videos/bg-video-cyan.mp4';
import { Footer } from './layout/footer';
import "aos/dist/aos.css";
import AOS from 'aos';
import { Home } from './pages/home';
import { useSelector } from 'react-redux';

function App() {

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: -120
    });
    setTimeout(() => {
      setLoading(true)
    }, 1000);
  }, []);

  const isDark = useSelector((state: any) => state.themeReducer.isDark)
  const videoRef = useRef<any>();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [isDark]);

  return (
    <div className="app">
      <div className="fade-load" hidden={loading}>
        <div className="loader1">
          <ul className="hexagon-container">
            <li className="hexagon hex_1"></li>
            <li className="hexagon hex_2"></li>
            <li className="hexagon hex_3"></li>
            <li className="hexagon hex_4"></li>
            <li className="hexagon hex_5"></li>
            <li className="hexagon hex_6"></li>
            <li className="hexagon hex_7"></li>
          </ul>
        </div>
      </div>
      <video playsInline autoPlay muted loop id="bgvid" className="bg-video" ref={videoRef}>
        <source src={isDark ? videoBackgroundCyan : videoBackground} type="video/webm" />
      </video>
      <Header />
      <main className="container">
        <Home />
      </main>
      <Footer />
    </div>
  );
}

export default App;
