import '../css/footer.scss';
import sperator from '../img/css/sperator-purple.svg';
import fade from '../img/css/footer-fade.png';
import logo from '../img/zodilab.png';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="fade" style={{ backgroundImage: `url(${fade})` }}></div>
                <div className="content">
                    <div className="logo-footer">
                        <img src={logo} className="images" alt="LOGO" loading="lazy" />
                    </div>
                    <div className="action">
                        <a href="https://discord.com/invite/catpunk" target="_blank" rel="noreferrer" className="btn-primary">
                            <span className="text glitch-text" data-text="JOIN OUR DISCORD">Join Our Discord</span>
                            <svg width={294} height={74} viewBox="0 0 294 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M280.055 15.5604L280.063 15.5686L280.071 15.5766L292.5 27.6347V72.5H1.5V1.5H266.002L280.055 15.5604Z" stroke="#FD24CA" strokeWidth={3} />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="sperator" style={{ backgroundImage: `url(${sperator})` }}></div>
            </div>
            <div className="endless">
                <div>made by ZodiLab</div>
                <div>All Rights Reserved 2021</div>
            </div>
        </footer>
    )
}