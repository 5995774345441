import '../css/plans.scss';
import video from '../videos/pricing-plan.mp4';

export const Plans = () => {

    return (
        <div className="plans">
            <div className="container">
                <div className="md:flex justify-between gap-content">
                    <div className="md:w-1/2" data-aos="fade-up">
                        <div className="title">Public Minting</div>
                        <div className="body">
                            + Mint Date: 11 AM November 11, 2021 UTC<br />
                            + What to mint: OG PASS - BLUE<br />
                            + Mint Price: 2 SOL<br />
                            + Method: First Come First Serve<br />
                            + Mint Quantity: 9249<br />
                        </div>
                        <div className="action">
                            <a href="https://mint.catpunk.net" target="_blank" rel="noreferrer" className="btn-primary">
                                <span className="text" data-text="Minting">Minting</span>
                                <svg width={251} height={74} viewBox="0 0 251 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M238.479 15.7982L238.488 15.8098L238.499 15.8213L249 27.7547V72H2V2H226.705L238.479 15.7982Z" stroke="#FD24CA" strokeWidth={4} />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="md:w-1/2" data-aos="zoom-out">
                        <div className="video">
                            <div className="bg-load-v">
                                <video height={500} controls={false} autoPlay loop playsInline muted={true} className="pricing-video">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
