import { useState } from 'react';
import '../css/faq.scss';
import data from '../data/faq.json';

export const Faq = () => {
    return (
        <section className="faq" id="faq">
            <div className="container">
                <h1 className="title" data-aos="fade-left">
                    <span>FAQ</span>
                    <span className="sub-right">#catpunk</span>
                </h1>
                <div className="body">
                    <div className="md:w-1/2">
                        {
                            data.slice(0, 8).map((value: any, index: number) => (
                                <div key={index} className="wrap" data-aos="fade-in">
                                    <Item data={value} index={index + 1} />
                                </div>
                            ))
                        }
                    </div>
                    <div className="md:w-1/2">
                        {
                            data.slice(8, 16).map((value: any, index: number) => (
                                <div key={index} className="wrap" data-aos="fade-in">
                                    <Item data={value} index={index + 9} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

const Item = (props: any) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    return (
        <div className="item" onClick={() => setIsCollapsed(!isCollapsed)}>
            <div className="head">
                <div className="name"><span className="mr-2">{props.index}.</span>{props.data.title}</div>
                <Icon isExpanded={isCollapsed} />
            </div>
            <div className={isCollapsed ? "content" : "content show"}>
                <form><div dangerouslySetInnerHTML={{ __html: props.data.body }}></div></form>
            </div>
            <div className="slash-top" hidden={props.index !== 1 && props.index !== 9}></div>
            <div className="slash-bottom" hidden={props.index !== 8 && props.index !== 15}></div>
        </div>
    )
}

const Icon = (props: any) => (
    !props.isExpanded ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 11H19V13H5V11Z" fill="white" />
        </svg>
    ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="white" />
        </svg>
    )
)