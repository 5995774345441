import '../css/overview.scss';
import overviewChar from '../img/overview-char.png';
import overviewCharDark from '../img/overview-char-dark.png';
import { useSelector } from "react-redux"

export const Overview = () => {

    const isDark = useSelector((state: any) => state.themeReducer.isDark)

    return (
        <section className={isDark ? "overview cyan" : "overview"} id="overview">
            <div className="container">
                <div className="md:flex flex-overview">
                    <div className="flex justify-center items-center">
                        <div className="image" data-aos="zoom-out">
                            <img src={isDark ? overviewCharDark : overviewChar} alt="OVERVIEW" loading="lazy" />
                        </div>
                    </div>
                    <div className="overtext" data-aos="fade-up">
                        <h1 className="title">WHAT IS CATPUNK</h1>
                        <div className="body">
                            The year is 2099, roughly 20 years after the biggest financial crisis known as the Big Crash had led to an all-out war among the superpowers that brought most of the world to ruin. The world was then renewed, with Paw City emerging as the first city to be rebuilt in merely 5 years with the help of technology. Strangely, the city was quickly populated by citizens amidst a rumor that they are all clones...
                        </div>
                        <div className="action">
                            <a className="btn-primary" href="https://info.catpunk.net/" target="_blank" rel="noreferrer">
                                <span className="glitch-text" data-text="READ MORE">read more</span>
                                <svg width={251} height={74} viewBox="0 0 251 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M238.479 15.7982L238.488 15.8098L238.499 15.8213L249 27.7547V72H2V2H226.705L238.479 15.7982Z" stroke="#FD24CA" strokeWidth={4} />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
