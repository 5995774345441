import '../css/team.scss';
import mem1 from '../img/teams/mem1.png';
import mem2 from '../img/teams/mem2.png';
import mem3 from '../img/teams/mem3.png';
import mem4 from '../img/teams/mem4.png';
import mem5 from '../img/teams/mem5.png';
import mem6 from '../img/teams/mem6.png';

export const Team = () => {
    return (
        <section className="team" id="team">
            <div className="container">
                <h1 className="title" data-aos="fade-up">Meet Our Team  the “Punkies” </h1>
                <div className="grid">
                    <Member img={mem1} name="MINH GEN-CHAN" jobTitle="FOUNDER - MAIN DEV" />
                    <Member img={mem2} name="HAN" jobTitle="3D ARTIST LEAD" />
                    <Member img={mem3} name="Ͼ-Jusye" jobTitle="2D ARTIST LEAD" />
                    <Member img={mem5} name="VeeWoo" jobTitle="FRONTEND" />
                    <Member img={mem6} name="Bill McSciFi" jobTitle="FICTION WRITER" />
                </div>
            </div>
        </section>
    )
}

const Member = (props: any) => {
    return (
        <div className="member" data-aos="fade-up">
            <img src={props.img} alt="MEMBER" className="avatar" loading="lazy"/>
            <div className="fade"></div>
            <div className="info">
                <div className="name">{props.name}</div>
                <div className="job-title">{props.jobTitle}</div>
            </div>
        </div>
    )
}
