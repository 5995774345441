import brand from "../img/brand-gradient.png";
import brandCyan from "../img/brand-cyan.png";
import mainCharacter from '../img/main-character.png';
import mainCharacterDark from '../img/main-character-dark.png';
import catpunk11 from '../img/catpunk-11.png';
import catpunk12 from '../img/catpunk-12.png';
import logo from '../img/catpunk-logo.png';
import logoDark from '../img/catpunk-logo-dark.png';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleDarkMode } from "../storages/actions/theme-action";
import '../css/glitch.scss';

export const Header = () => {

    const [isDark, setIsDark] = useState<any>(localStorage.getItem("theme") || false)
    const [skipped, setSkipped] = useState<boolean>(localStorage.getItem("theme") === 'true')
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    dispatch(toggleDarkMode(isDark))

    useEffect(() => {
        document.getElementsByTagName("HTML")[0].setAttribute("data-theme", localStorage.getItem("theme") || '');
        setSkipped(localStorage.getItem("skipped") === 'true');
    }, []);

    const handleSkip = () => {
        setSkipped(true);
        localStorage.setItem("skipped", 'true');
    }

    const toggleThemeChange = (isDark: boolean) => {
        if ((isDark && localStorage.getItem("theme")) || (!isDark && !localStorage.getItem("theme"))) {
            return
        }
        setLoading(true)
        if (isDark) {
            localStorage.setItem("theme", "dark");
            document
                .getElementsByTagName("HTML")[0]
                .setAttribute("data-theme", "dark");
        } else {
            localStorage.setItem("theme", "");
            document
                .getElementsByTagName("HTML")[0]
                .setAttribute("data-theme", "");
        }
        setIsDark(isDark)
        dispatch(toggleDarkMode(isDark))
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    return (
        <header className="header">
            <div className="fade-load" hidden={!loading}>
                <div className="loader1">
                    <ul className="hexagon-container">
                        <li className="hexagon hex_1"></li>
                        <li className="hexagon hex_2"></li>
                        <li className="hexagon hex_3"></li>
                        <li className="hexagon hex_4"></li>
                        <li className="hexagon hex_5"></li>
                        <li className="hexagon hex_6"></li>
                        <li className="hexagon hex_7"></li>
                    </ul>
                </div>
            </div>
            <nav className="navbar">
                <div className="fade-step" hidden={skipped} onClick={handleSkip}></div>
                <div className="brand">
                    <a href="/"><img src={isDark ? brandCyan : brand} alt="Brand" className="brand-image" /></a>
                </div>
                <div className="nav-right">
                    <ul className="menu">
                        <li>
                            <a href="https://catpunk.net/">Main Page</a>
                        </li>
                        <li>
                            <a href="https://gallery.catpunk.net/" target="_blank" rel="noreferrer">3D Gallery</a>
                        </li>
                        <li>
                            <a href="https://info.catpunk.net/" target="_blank" rel="noreferrer">LITEPAPER/FICTION</a>
                        </li>
                        <li className="tool">
                            <button className={isDark ? 'dark bg-primary' : 'dark'} onClick={() => toggleThemeChange(true)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.4663 14.9794C19.7534 14.3132 19.1287 13.6738 18.4032 13.6738C14.2394 13.6738 10.8639 10.2983 10.8639 6.13449C10.8639 5.40899 10.2245 4.78424 9.55825 5.07135C6.87701 6.22678 5 8.89337 5 11.9984C5 16.1622 8.37547 19.5377 12.5393 19.5377C15.6443 19.5377 18.3109 17.6607 19.4663 14.9794Z" fill="white" />
                                </svg>
                            </button>
                            <button className="light" onClick={() => toggleThemeChange(false)}>
                                <span hidden={isDark} className="bg-light-active">
                                    <svg width={57} height={30} viewBox="0 0 57 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0H47.2031L57 11.1635V30H0V0Z" fill="#FD24CA" />
                                    </svg>
                                </span>
                                <span>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sun">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 17.5C15.0376 17.5 17.5 15.0376 17.5 12C17.5 8.96243 15.0376 6.5 12 6.5C8.96243 6.5 6.5 8.96243 6.5 12C6.5 15.0376 8.96243 17.5 12 17.5Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9951 2C12.5474 2 12.9951 2.44772 12.9951 3V4C12.9951 4.55228 12.5474 5 11.9951 5C11.4428 5 10.9951 4.55228 10.9951 4V3C10.9951 2.44772 11.4428 2 11.9951 2Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9951 18.9996C12.5474 18.9996 12.9951 19.4473 12.9951 19.9996V20.9964C12.9951 21.5487 12.5474 21.9964 11.9951 21.9964C11.4428 21.9964 10.9951 21.5487 10.9951 20.9964V19.9996C10.9951 19.4473 11.4428 18.9996 11.9951 18.9996Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.92537 4.92842C5.31589 4.5379 5.94906 4.5379 6.33958 4.92842L7.20405 5.79289C7.59458 6.18342 7.59458 6.81658 7.20405 7.20711C6.81353 7.59763 6.18036 7.59763 5.78984 7.20711L4.92537 6.34264C4.53484 5.95211 4.53484 5.31895 4.92537 4.92842Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7931 16.796C17.1836 16.4055 17.8168 16.4055 18.2073 16.796L19.0651 17.6538C19.4556 18.0444 19.4556 18.6775 19.0651 19.0681C18.6745 19.4586 18.0414 19.4586 17.6509 19.068L16.793 18.2102C16.4025 17.8197 16.4025 17.1865 16.7931 16.796Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.99707 11.9982C1.99707 11.4459 2.44479 10.9982 2.99707 10.9982H3.99707C4.54936 10.9982 4.99707 11.4459 4.99707 11.9982C4.99707 12.5505 4.54936 12.9982 3.99707 12.9982H2.99707C2.44479 12.9982 1.99707 12.5505 1.99707 11.9982Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M18.9966 11.9982C18.9966 11.4459 19.4443 10.9982 19.9966 10.9982H20.9934C21.5456 10.9982 21.9934 11.4459 21.9934 11.9982C21.9934 12.5505 21.5456 12.9982 20.9934 12.9982H19.9966C19.4443 12.9982 18.9966 12.5505 18.9966 11.9982Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.92522 19.068C4.53469 18.6775 4.53469 18.0444 4.92522 17.6538L5.78615 16.7929C6.17667 16.4024 6.80984 16.4024 7.20036 16.7929C7.59089 17.1834 7.59089 17.8166 7.20036 18.2071L6.33943 19.068C5.94891 19.4586 5.31574 19.4586 4.92522 19.068Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.786 7.20711C16.3955 6.81658 16.3955 6.18341 16.7861 5.7929L17.6506 4.9284C18.0411 4.53789 18.6743 4.5379 19.0648 4.92843C19.4553 5.31896 19.4553 5.95212 19.0648 6.34264L18.2002 7.20714C17.8097 7.59765 17.1765 7.59764 16.786 7.20711Z" fill="white" />
                                    </svg>
                                    <svg width={59} height={34} viewBox="0 0 59 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="border-light">
                                        <path d="M52.7384 7.64392L52.7459 7.65259L52.7536 7.66114L57.5 12.9776V32.5H1.5V1.5H47.4198L52.7384 7.64392Z" stroke="#FD24CA" strokeWidth={3} />
                                    </svg>
                                </span>
                            </button>
                            <div className="tip" hidden={skipped}>
                                <svg width={105} height={94} viewBox="0 0 105 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M72.0623 91.9L72.0648 93.4L72.0623 91.9ZM11.9625 32L10.4625 32.0025L11.9625 32ZM10.8501 0.941106C11.4349 0.35435 12.3847 0.352766 12.9714 0.937571L22.5332 10.4676C23.12 11.0524 23.1216 12.0022 22.5368 12.5889C21.952 13.1757 21.0022 13.1773 20.4155 12.5925L11.9161 4.12132L3.44491 12.6207C2.8601 13.2075 1.91035 13.2091 1.3236 12.6242C0.73683 12.0394 0.735259 11.0897 1.32006 10.5029L10.8501 0.941106ZM104.065 93.3467L72.0648 93.4L72.0598 90.4L104.06 90.3467L104.065 93.3467ZM10.4625 32.0025L10.4125 2.0025L13.4125 1.9975L13.4625 31.9975L10.4625 32.0025ZM72.0648 93.4C38.0993 93.4565 10.519 65.9679 10.4625 32.0025L13.4625 31.9975C13.5163 64.3061 39.7512 90.4538 72.0598 90.4L72.0648 93.4Z" fill="white" />
                                </svg>
                                <div className="tip-text">Click here to change theme</div>
                                <button className="btn-skip" onClick={handleSkip}>SKIP</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="catpunk11" style={{ backgroundImage: `url(${isDark ? catpunk12 : catpunk11})` }}></div>
            <section className="md:flex">
                <div className="md:w-1/2 md:flex">
                    <div className="logo" style={{ backgroundImage: `url(${isDark ? logoDark : logo})` }}></div>
                    <div className="social">
                        <div className="text">
                            Follow us on
                        </div>
                        <div className="line"></div>
                        <div className="icons">
                            <div className="icon">
                                <a href="https://discord.com/invite/catpunk" target="_blank" rel="noreferrer" className="link">
                                    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_69:137)">
                                            <path d="M21.1636 1.69197C19.5701 0.974669 17.8614 0.446193 16.0749 0.143512C16.0424 0.137671 16.0099 0.152269 15.9931 0.181467C15.7733 0.564925 15.5299 1.06518 15.3595 1.45837C13.4379 1.17614 11.5262 1.17614 9.64408 1.45837C9.47359 1.05644 9.22134 0.564925 9.0006 0.181467C8.98384 0.153243 8.95134 0.138645 8.9188 0.143512C7.13324 0.445225 5.42454 0.973702 3.83014 1.69197C3.81634 1.69781 3.80451 1.70755 3.79666 1.72019C0.555616 6.47068 -0.33224 11.1044 0.103313 15.6807C0.105283 15.7031 0.118094 15.7245 0.135831 15.7381C2.27418 17.2787 4.34553 18.2141 6.37842 18.834C6.41095 18.8438 6.44542 18.8321 6.46613 18.8058C6.94701 18.1615 7.37567 17.4822 7.7432 16.7678C7.76489 16.7259 7.74419 16.6763 7.69986 16.6598C7.01993 16.4067 6.3725 16.0982 5.74972 15.7478C5.70046 15.7196 5.69651 15.6505 5.74183 15.6174C5.87289 15.521 6.00398 15.4208 6.12912 15.3196C6.15176 15.3011 6.18331 15.2972 6.20993 15.3089C10.3013 17.1415 14.7308 17.1415 18.7739 15.3089C18.8005 15.2962 18.8321 15.3001 18.8557 15.3186C18.9809 15.4198 19.1119 15.521 19.244 15.6174C19.2893 15.6505 19.2863 15.7196 19.2371 15.7478C18.6143 16.105 17.9669 16.4067 17.2859 16.6588C17.2416 16.6753 17.2219 16.7259 17.2436 16.7678C17.619 17.4812 18.0477 18.1605 18.5197 18.8048C18.5394 18.8321 18.5749 18.8438 18.6074 18.834C20.6501 18.2141 22.7215 17.2787 24.8598 15.7381C24.8786 15.7245 24.8904 15.704 24.8924 15.6816C25.4136 10.391 24.0193 5.79525 21.1961 1.72116C21.1892 1.70755 21.1774 1.69781 21.1636 1.69197ZM8.35419 12.8942C7.12239 12.8942 6.10743 11.7847 6.10743 10.4221C6.10743 9.05955 7.10271 7.95005 8.35419 7.95005C9.61549 7.95005 10.6206 9.06929 10.6009 10.4221C10.6009 11.7847 9.60563 12.8942 8.35419 12.8942ZM16.6612 12.8942C15.4294 12.8942 14.4145 11.7847 14.4145 10.4221C14.4145 9.05955 15.4097 7.95005 16.6612 7.95005C17.9225 7.95005 18.9276 9.06929 18.908 10.4221C18.908 11.7847 17.9225 12.8942 16.6612 12.8942Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_69:137">
                                                <rect width="25" height="19" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://twitter.com/ZodiLabOfficial" target="_blank" rel="noreferrer" className="link">
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_69:140)">
                                            <path d="M25 4.74688C24.0802 5.15522 23.0917 5.43022 22.0542 5.55417C23.1135 4.9198 23.9271 3.91459 24.3094 2.71667C23.3187 3.30417 22.2208 3.73126 21.0521 3.96147C20.1177 2.96459 18.7833 2.34167 17.3083 2.34167C13.9969 2.34167 11.5635 5.43126 12.3115 8.63855C8.05 8.42501 4.27083 6.38334 1.74062 3.28022C0.396875 5.58542 1.04375 8.60105 3.32708 10.1281C2.4875 10.101 1.69583 9.87084 1.00521 9.48647C0.948958 11.8625 2.65208 14.0854 5.11875 14.5802C4.39687 14.776 3.60625 14.8219 2.80208 14.6677C3.45417 16.7052 5.34792 18.1875 7.59375 18.2292C5.4375 19.9198 2.72083 20.675 0 20.3542C2.26979 21.8094 4.96667 22.6583 7.8625 22.6583C17.3854 22.6583 22.7656 14.6156 22.4406 7.40209C23.4427 6.67813 24.3125 5.77501 25 4.74688Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_69:140">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <a href="https://discord.com/invite/catpunk" target="_blank" rel="noreferrer" className="join-us">
                        <svg width={251} height={74} viewBox="0 0 251 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M238.479 15.7982L238.488 15.8098L238.499 15.8213L249 27.7547V72H2V2H226.705L238.479 15.7982Z" stroke="#FD24CA" strokeWidth={4} />
                        </svg>
                        <span className="text glitch-text" data-text="JOIN US">Join Us</span>
                    </a>
                    <a href="https://mint.catpunk.net" target="_blank" rel="noreferrer" className="btn-primary minting">
                        <svg width={251} height={74} viewBox="0 0 251 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M238.479 15.7982L238.488 15.8098L238.499 15.8213L249 27.7547V72H2V2H226.705L238.479 15.7982Z" stroke="#FD24CA" strokeWidth={4} />
                        </svg>
                        <span className="text" data-text="Minting" style={{ color: 'rgb(194, 192, 192)' }}>Minting</span>
                    </a>
                </div>
                <div className="md:w-1/2">
                    <div className="character">
                        <div className="glitch">
                            <div className="glitch_image" style={{ backgroundImage: `url(${isDark ? mainCharacterDark : mainCharacter})` }}>
                            </div>
                            <div className="glitch_image" style={{ backgroundImage: `url(${isDark ? mainCharacterDark : mainCharacter})` }}>
                            </div>
                            <div className="glitch_image" style={{ backgroundImage: `url(${isDark ? mainCharacterDark : mainCharacter})` }}>
                            </div>
                            <div className="glitch_image" style={{ backgroundImage: `url(${isDark ? mainCharacterDark : mainCharacter})` }}>
                            </div>
                            <div className="glitch_image" style={{ backgroundImage: `url(${isDark ? mainCharacterDark : mainCharacter})` }}>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copyright glitch-text" data-text="By Zodilab  |  Catpunk 3H.W.90">By Zodilab  |  Catpunk 3H.W.90</div>
            <div className="sperator"></div>
        </header>
    )
}
