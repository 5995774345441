import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss'
import '../css/gallery.scss';
import cat1 from '../img/gallery/cat1.jpg';
import cat2 from '../img/gallery/cat2.jpg';
import cat3 from '../img/gallery/cat3.jpg';
import cat4 from '../img/gallery/cat4.jpg';
import cat5 from '../img/gallery/cat5.jpg';
import bgGallery from '../img/css/bg-gallery.png';
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

export const Gallery = () => {

    const [swiperRef, setSwiperRef] = useState<any>(null);

    const handleClick = (next: boolean) => {
        if (next) {
            swiperRef.slideNext()
        } else {
            swiperRef.slidePrev()
        }
    }

    return (
        <section className="gallery" id="gallery" style={{ backgroundImage: `url(${bgGallery})` }}>
            <div className="container">
                <div className="flex justify-between">
                    <div>
                        <h1 className="title" data-aos="slide-right" data-aos-duration="500">The gallery</h1>
                    </div>
                    <div className="tool">
                        <button className="btn-slide previous" onClick={() => handleClick(false)}>
                            <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.1042 22.5625L6.56249 13L16.1042 3.4375L13.1667 0.5L0.666656 13L13.1667 25.5L16.1042 22.5625Z" fill="#33FFFE" />
                            </svg>
                        </button>
                        <button className="btn-slide next" onClick={() => handleClick(true)}>
                            <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.895812 22.5625L10.4375 13L0.895812 3.4375L3.83331 0.5L16.3333 13L3.83331 25.5L0.895812 22.5625Z" fill="#33FFFE" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="slides">
                    <Swiper
                        slidesPerView={1}
                        autoplay={{
                            "delay": 1000,
                            "disableOnInteraction": false
                        }}
                        loop={true}
                        centeredSlides={true}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2
                            },
                            1534: {
                                slidesPerView: 3
                            },
                            1930: {
                                slidesPerView: 4
                            }
                        }}
                        loopAdditionalSlides={100}
                        onSwiper={setSwiperRef}>
                        <SwiperSlide>
                            <div className="tour">
                                <img src={cat1} alt="" className="w-full im-gl" loading="lazy" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tour">
                                <img src={cat2} alt="" className="w-full im-gl" loading="lazy" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tour">
                                <img src={cat3} alt="" className="w-full im-gl" loading="lazy" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tour">
                                <img src={cat4} alt="" className="w-full im-gl" loading="lazy" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tour">
                                <img src={cat5} alt="" className="w-full im-gl" loading="lazy" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <a className="btn-join" href="https://discord.com/invite/catpunk" target="_blank" rel="noreferrer">
                <span className="text">Join Us</span>
                <span className="icon">
                    <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 10.5H28.3333" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M20.1667 2.33325L28.3334 10.4999L20.1667 18.6666" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className="scan-glitch"></span>
            </a>
        </section>
    )
}