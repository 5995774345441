const initialState: any = {
    isDark: false,
}

const textReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case 'TOGGLE_DARK_MODE': {
            return {
                ...state,
                isDark: action.payload
            }
        }
        default:
            return state
    }
}

export default textReducer