
import { Faq } from '../components/faq';
import { Gallery } from '../components/gallery';
import { Overview } from '../components/overview';
import { Plans } from '../components/plans';
import { Road } from '../components/road';
import { Team } from '../components/team';

export const Home = () => {
    return (
        <div className="home">
            <Overview />
            <Gallery />
            <Plans />
            <Team />
            <Faq />
        </div>
    )
}
